<template>
  <div class="allcontens">
    <div class="content">
      <div class="contentAg">
        <p class="titleAg">委托付款及电子扣款授权确认书</p>
        <p class="lightColour">
          本确认书由您向通联支付网络服务股份有限公司（下称"通联支付"）与深圳聚瑞云控科技有限公司（下称"聚瑞云控"）出具，具有授权及承诺之法律效力。请您务必审慎阅读、充分理解本确认书各条款内容。您同意本确认书即视为您已向通联支付提供扣款授权，并视为您委托聚瑞云控代您支付款项，您自愿承担由此导致的一切法律后果。如您有任何疑问，应向通联支付客服咨询。
        </p>
        <div class="Dark">因您向
          <span class="span_underline" id="companyName">{{ item.productName}}</span>（以下简称为"商户"）购买了
          <span class="span_underline" id="service">{{ item.productName}}</span>服务，且您同意委托聚瑞云控代您向商户支付购买款项，且您同意向聚瑞云控支付垫付款项，您确认并授权聚瑞云控向通联支付发出扣款指令，通联支付即可在不验证您的账户密码、短信动态码等信息的情况下直接从您的银行结算账户中扣划聚瑞云控指定的款项至聚瑞云控指定账户。商户将从您账户扣款
          <span class="span_underline" id="frequency">{{ item.quantity}}</span>次，<br>
          <div class="repay-list">
            <div class="repay-item" v-for="e in itemSort" :key="e.period">
              于<span class="span_underline" id="YY">{{e.repayDateDesc}}</span> 第<span class="span_underline">{{e.period}}</span>次扣款<span class="span_underline" id="money">{{e.periodPayment}}</span>元；
            </div>
          </div>
        </div>
        <p class="lightColour">
          在任何情况下，只要聚瑞云控向通联支付发出支付指令，通联支付就可按照该指令进行资金扣划，通联支付对聚瑞云控的支付指令在实质上的正确性、合法性、完整性、真实性不承担任何法律责任，相关法律责任由您和聚瑞云控自行承担。</p>
        <p class="lightColour">
          您在扣款账户内必须预留有足够的被扣划的余额，否则因账户余额不足导致无法及时扣款或扣款错误、失败，责任由您自行承担。因不可归责于通联支付的事由，导致的不能及时划付款项、划账错误等责任与通联支付无关。</p>
        <p class="lightColour">您确认，因聚瑞云控的原因导致您遭受经济损失的，由您与聚瑞云控协商解决，与通联支付无关。如因通联支付原因导致您遭受经济损失的，由通联支付承担责任。</p>
        <p class="lightColour">本确认书的出具、解释以及由本确认书引起或与本确认书有关的纠纷的解决均适用中华人民共和国法律。本人/本公司同意并接受本确认书由原告方所在地有管辖权的人民法院管辖。</p>
        <!-- <p class="address">发票地址(可不填)：<input type="text" class="addressinput" id="addressinput"></p> -->
      </div>
      <div class="row justify-center">
        <van-button round type="info" size="small" class="margin-top-30 margin-bottom-20 next-btn" @click="validation">确认阅读</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { queryContractInfo } from '@/api/sign'
  import storage from '@/utils/storage'
  export default {
    data() {
      return {
        insuranceNo: '',
        item: {}
      }
    },
    computed: {
      itemSort() {
        if (!this.item.repayDateList) {
          return []
        }
        const data = this.item.repayDateList
        return data.sort((a, b) => a.period - b.period)
      }
    },
    async created() {
      this.insuranceNo = storage.getItem('insuranceNo')
      if (!this.insuranceNo) {
        this.$toast.fail('请在同一设备上完成所有代扣协议的签署。')
        return
      }
      const res = await queryContractInfo({ insuranceNo: this.insuranceNo })
      if (!res.success) {
        this.$toast.fail('信息获取异常')
      }
      this.item = res.content
    },
    methods: {
      validation() {
        this.$router.go(-1)
      },
      compare(obj1, obj2) {
        const val1 = obj1.period
        const val2 = obj2.period
        if (val1 > val2) {
          return 0
        } else if (val1 < val2) {
          return -1
        } else {
          return 0
        }
      }
    }
  }
</script>
<style scoped>
  .contentAg {
    width: 100%;
    padding: 0 00 0.4rem;
  }

  .titleAg {
    font-size: 0.46rem;
    color: black;
    font-weight: 600;
    text-align: center;
    line-height: 1rem;
  }

  .contentAg>p {
    margin-top: 0.2rem;
  }

  .smsnext {
    margin: 1rem auto;
  }

  .lightColour {
    color: #8A8A8A;
    font-size: 0.4rem;
    text-indent: 2em;
  }

  .Dark {
    color: black;
    font-size: 0.4rem;
    text-indent: 2em;
  }

  .address {
    margin-top: 0.5rem;
    font-size: 0.4rem;
  }

  .addressinput {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0.2rem;
    box-sizing: border-box;
    width: 3rem;
    font-size: 0.26rem;
    border-radius: 0;
    border-bottom: 1px solid #8A8A8A;
  }

  .span_underline {
    text-align: center;
    border-bottom: 1px solid black;
    font-size: 0.4rem;
    padding-left: 0.15rem;
    padding-right: 0.15rem;
  }

  .repay-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.1rem 0;
  }

  .repay-list .repay-item {
    padding: 0.08rem 0;
  }
</style>